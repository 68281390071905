import React from "react";
import Footer from "src/components/Footer_en";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/en/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/en/our-products/" target="_self">
                      Our Products
                    </a>
                    <ul>
                      <li>
                        <a href="/en/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/ultra_light/">Ultra Light</a>
                      </li>
                      <li>
                        <a href="/en/our-products/protect_cool/">
                          Protect &amp; Cool
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/instantglow/">Instant Glow</a>
                      </li>
                      <li>
                        <a href="/en/our-products/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/en/our-products/wetskin/">Wet skin</a>
                      </li>
                      <li>
                        <a href="/en/our-products/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/en/our-products/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/en/our-products/all_products/">
                          All products
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/en/our-heritage/" target="_self">
                      Our Heritage
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/en/know_the_sun/" target="_self">
                      Know the sun
                    </a>
                  </li>
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      countries
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/protect_cool/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/protect_cool/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/protect_cool/">ES</a>
                      </li>
                      
                      <li>
                        <a href="/it/prodotti/protect_cool/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/protect_cool/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/2017-pizbuin_1680x405px_ProtectCool.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/Protect_Cool_960x405px_top.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      Piz Buin Protect &amp; Cool<sup>®</sup>
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>Skin refreshing protection</h3>
                      <p>
                        Stay protected and unbelievably fresh on even the
                        hottest days. PIZ BUIN Protect &amp; Cool<sup>®</sup>{" "}
                        Refreshing Sun Mousse delivers advanced UVA/UVB
                        protection in a sensationally light and cooling foam
                        that absorbs effortlessly, leaving your skin with a
                        fresh, summer feeling… like you’re not wearing sunscreen
                        at all!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="refreshing-sun-mousse" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2ad9e4d77e0452083037617cf04799ea_f368.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>Refreshing Sun Mousse</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFITS</h4>
                        <p>
                          This refreshing sun mousse gives an immediate and
                          pleasurable icy cooling sensation and is free from
                          alcohol that is known for its tendency to dry out the
                          skin. This innovative and easy to apply hydrating
                          formulation is instantly absorbing, non-greasy,
                          non-sticky and doesn’t leave white marks.
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>AVAILABLE IN SPF</h4>
                        <span className="factor factor10" />
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>DIRECTIONS</h4>
                        <p>
                          Apply generously and evenly before sun exposure. A
                          reduced quantity lowers the protection level
                          significantly. Reapply frequently, especially after
                          sweating, swimming or towelling. Avoid midday sun and
                          staying too long in the sun even while using
                          sunscreens. Keep babies and children out of direct
                          sunlight. For use on face, spray into hands and apply.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTS AND TECHNOLOGIES{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                ADVANCED UVA/UVB SUN FILTER SYSTEM
                                <div className="textBlock bottom">
                                  <p>
                                    As suncare specialists, we use innovative
                                    technologies to make sure you achieve the
                                    right balance of colour and protection
                                    whenever you enjoy the sun.
                                  </p>
                                  <p>
                                    Each of the advanced UVA/UVB sun filter
                                    systems is a powerful sun protection
                                    technology developed by PIZ BUIN<sup>®</sup>
                                    . They offer effective photostable,
                                    broad-spectrum UVA and UVB protection, and
                                    help achieve that PIZ BUIN<sup>®</sup>{" "}
                                    sunscreens comply with the latest European
                                    Commission recommendation on sunscreen
                                    products.
                                  </p>
                                  <p>
                                    All of the advanced UVA/UVB sun filter
                                    systems help to protect from UVA and UVB
                                    radiation.
                                  </p>
                                  <p>
                                    UVB rays - "burning rays" - harm the skin’s
                                    surface and are the main cause of sunburn.
                                  </p>
                                  <p>
                                    UVA rays – "aeging rays" - penetrate deeper
                                    and damage collagen and elastin, which can
                                    result in premature skin ageing and cancer.
                                  </p>
                                  <p>
                                    The advanced UVA/UVB sun filter systems
                                    contain both UVA and UVB filters to help
                                    protect against the sun and long term skin
                                    damage while also protecting against the
                                    more serious effects of UVA radiation. They
                                    cover most of the UVA / UVB spectrum,
                                    offering broadband protection for your skin.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/en/our-products/moisturising/"
                      target="_self"
                    >
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/ultra_light/" target="_self">
                      Ultra Light
                    </a>
                  </li>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/en/our-products/protect_cool/"
                      target="_self"
                    >
                      Protect &amp; Cool
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/tan_protect/" target="_self">
                      Tan &amp; Protect
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/instantglow/" target="_self">
                      Instant Glow
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/allergy/" target="_self">
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/wetskin/" target="_self">
                      Wet skin
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/mountain/" target="_self">
                      Mountain
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/aftersun/" target="_self">
                      After sun
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/all_products/" target="_self">
                      All products
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Read More');\n} else {\nbox.slideDown();\n$(this).text('Read Less');\n}\n});\n});\n"
          }}
        />
        
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
